import { template as template_e04d57914b084efbba574ba67086a12e } from "@ember/template-compiler";
const WelcomeHeader = template_e04d57914b084efbba574ba67086a12e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
