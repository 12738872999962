import { template as template_7e4d10957ad845d68f04cb7042788177 } from "@ember/template-compiler";
const FKText = template_7e4d10957ad845d68f04cb7042788177(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
